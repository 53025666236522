<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0 d-flex justify-content-between">
                <h3 class="mb-0">承認代理一覧</h3>
                <div class="mt--1">
                  <div
                    id="add-approval-proxy"
                    class="icon-shape text-white rounded-circle shadow bg-info icon-action"
                    @click="toAddProxy"
                  >
                    <i class="fas fa-plus"></i>
                  </div>
                </div>
                <b-tooltip target="add-approval-proxy" triggers="hover">
                  承認代理を追加する
                </b-tooltip>
              </b-card-header>
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(org_id)="row">
                  {{ getOrganizationName(row.value) }}
                </template>
                <template #cell(is_silence)="row">
                  <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </template>
                <template #cell(actions)="row">
                  <b-button size="sm" variant="link" :to="{ name: 'proxy-edit', params: {id: row.item.id} }">
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ajax, common, config } from '../../utils'
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'workflow_name', label: 'ワークフロー名称' },
        { key: 'org_id', label: '適用部署' },
        { key: 'dst_user_name', label: '指定の承認者名' },
        { key: 'is_silence', label: '自分に通知しない', class: 'text-center' },
        { key: 'actions', label: 'Actions', class: 'text-center' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    ...mapGetters('account', ['getOrganizationName']),
    totalRows: function() {
      return this.items.length;
    },
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getApprovalProxyList();
  },
  methods: {
    getApprovalProxyList() {
      const vm = this;
      ajax.fetchGet(config.api.account.approval_proxy_list).then(data => {
        vm.items = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    toAddProxy() {
      this.$router.push({ name: 'proxy-add' });
    },
  },
}
</script>
